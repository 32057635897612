
import React, {useState} from "react"

import OnsorderPage from "../controls/onsordereditor";
import PreviewpaneComponent from "../../previewpane";
import WebappWidgetCard from "../../webapps/widget/card";
import WebappWidgetTable from "../../webapps/widget/table";

import * as tablescroll from "../../../styles/module/tablescroll.module.css"
import * as styles from "../../webapps/style.module.css"

import formatTools from "../../../../lib/formatTools"
import webappsAPI from "../../../../lib/requestWebapps"

const customerFields = [
	{"label":"Last Name", "dbfield": "onsperson_lastname", "type": "text", "filtertype": "textbox"},
	{"label":"First Name", "dbfield": "onsperson_firstname", "type": "text", "filtertype": "textbox"},
	{"label":"Login Type", "dbfield": "onsperson_codetype", "type": "combo", "filtertype": "textbox", "options":[
		{"value":0,"display":"-"},
		{"value":1,"display":"Password"},
		{"value":2,"display":"Guest"},
	]},
];

/*
	const USERLOGINTYPE_UNKNOWN = 0;
	const USERLOGINTYPE_PASSWORD = 1;
	const USERLOGINTYPE_GUEST = 2;

*/

const addressFields = [
	{"label":"Address", "dbfield": "onsaddress_name", "type": "text", "filtertype": "textbox"},
	{"label":"Landmark", "dbfield": "onsaddress_landmark", "type": "text", "filtertype": "textbox"},
	{"label":"Notes", "dbfield": "onsaddress_restrictions", "type": "text", "filtertype": "textbox"},
	{"label":"Primary", "dbfield": "onspersonaddress_primary", "type": "checkbox", "filtertype": "checkbox"},
];

const addressMobileTitle = ["onsaddress_name"];

const addressInitState = {
	"orderbyfield": "onspersonaddress_primary",
	"orderbymode": "desc"
};

const orgFields = [
	{"label":"Organization", "dbfield": "onsorganization_shortname", "type": "text", "filtertype": "textbox"},
	{"label":"Organization Type", "dbfield": "onsorganization_notes", "type": "text", "filtertype": "textbox"},
	{"label":"Role/Position", "dbfield": "onspersonorganization_role", "type": "text", "filtertype": "textbox"},
	{"label":"Start", "dbfield": "onspersonorganization_start", "type": "date", "filtertype": "date"},
	{"label":"End", "dbfield": "onspersonorganization_end", "type": "date", "filtertype": "date"},
];

const orgMobileTitle = ["onsorganization_shortname"];


const orgInitState = {
	"orderbyfield": "onspersonorganization_start",
	"orderbymode": "desc"
};

const contactFields = [
	{"label":"Contact", "dbfield": "onscontact_info", "type": "text", "filtertype": "textbox"},
	{"label":"Type", "dbfield": "onscontacttype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Notes", "dbfield": "onspersoncontact_notes", "type": "text", "filtertype": "textbox"},
	{"label":"Primary", "dbfield": "onspersoncontact_primary", "type": "checkbox", "filtertype": "checkbox"}
];

const contactMobileTitle = ["onscontact_info"];

const contactInitState = {
	"orderbyfield": "onscontacttype_name",
	"orderbymode": "desc"
};

const orderFields = [
	{"label":"Order ID", "dbfield": "onsorderform_id", "type": "hidden", "filtertype": "none"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"Created", "dbfield": "onsorderform_datetime", "type": "datetime", "filtertype": "none"},
	{"label":"Method", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "none"},
	{"label":"Method Details", "dbfield": "nnpickup_name", "type": "text", "filtertype": "none"},
	{"label":"Status", "dbfield": "onsorderformstatus_name", "type": "text", "filtertype": "none"},
	{"label":"Last Updated", "dbfield": "onsorderform_updatetime", "type": "datetime", "filtertype": "none"},
	//onsorderformfulfillment_id
	{"label":"Ordered Amount", "dbfield": "onsorderform_totalamount", "type": "currency", "filtertype": "currency"},
	{"label":"Ordered PCs", "dbfield": "onsorderform_totalpcs", "type": "integer", "filtertype": "integer"},
	{"label":"Available Amount", "dbfield": "onsorderform_availableamount", "type": "currency", "filtertype": "currency"},
	{"label":"Served Amount", "dbfield": "onsorderform_deliveramount", "type": "currency", "filtertype": "currency"},
];

const orderMobileTitle = ["onsaddress_name"];

const orderFilterFields = [
	{"label":"Order #", "dbfield": "onsorderform_code", "filtertype": "textbox"},
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "filtertype": "date"},
	{"label":"Status", "dbfield": "onsorderformstatus_name", "filtertype": "textbox"},
];


const CustomerProfileComponent = ({location, customerid, token, handleClose}) => {
	const [customername, setCustomername] = useState("Back");
	const [panevisible, setPanevisible] = useState(false);
	const [panetitle, setPanetitle] = useState("");
	const [customlocation, setCustomlocation] = useState({});

	const defaultstartdate = formatTools.getDateStr(8-24*180); // ~6months ago

	function setCustomerInfo(newdata)
	{
		if (newdata.hasOwnProperty("onsperson_lastname") && newdata.hasOwnProperty("onsperson_firstname")) {
			var tmpname = "";
			if (newdata.onsperson_lastname !== null) {
				tmpname = newdata.onsperson_lastname;
			}
			if (newdata.onsperson_firstname !== null) {
				if (newdata.onsperson_firstname.length > 0) {
					if (tmpname.length > 0)
						tmpname = tmpname+", ";
					tmpname = tmpname+newdata.onsperson_firstname;
				}
			}
			if (tmpname.length >0) {
				setCustomername(tmpname);
			}
		}
	}

	function orderformRowClick(e, rowdata, searchstate)
	{
		const linkprefix = "00";
		const querystring = "?viewmode=true&docid="+webappsAPI.encodePayload(linkprefix+rowdata.onsorderform_id, "b");
		setPanetitle("Order "+rowdata.onsorderform_code);
		//setPaneurlsrc(location.origin+"/order/allorder);
		setPanevisible(true);
		setCustomlocation({
			"search": querystring
		});
	}

	const customerQueryparams = {
		//tablelist: [],
		joinlist: [],
		condlist: ["onsperson_id="+customerid]
	};

	const addressQueryparams = {
		//tablelist: [],
		joinlist: [{type:"left", table: "ONSADDRESS", condlist: ["ONSADDRESS.onsaddress_id=ONSPERSONADDRESS.onsaddress_id"]}],
		condlist: ["ONSPERSONADDRESS.onsperson_id="+customerid]
	};

	const orgQueryparams = {
		//tablelist: [],
		joinlist: [{type:"left", table: "ONSORGANIZATION", condlist: ["ONSORGANIZATION.onsorganization_id=ONSPERSONORGANIZATION.onsorganization_id"]}],
		condlist: ["ONSPERSONORGANIZATION.onsperson_id="+customerid]
	};

	const contactQueryparams = {
		tablelist: ["ONSCONTACTTYPE","ONSCONTACT"],
		joinlist: [],
		condlist: ["ONSPERSONCONTACT.onscontact_id=ONSCONTACT.onscontact_id", "ONSCONTACTTYPE.onscontacttype_id=ONSCONTACT.onscontacttype_id", "ONSPERSONCONTACT.onsperson_id="+customerid]
	};

	const orderQueryparams = {
		//tablelist: [],
		joinlist: [
			{type:"left", table: "ONSORDERFORMSTATUS", condlist: ["ONSORDERFORMSTATUS.onsorderformstatus_id=ONSORDERFORM.onsorderformstatus_id"]},
			{type:"left", table: "NNPICKUP", condlist: ["NNPICKUP.nnpickup_id=ONSORDERFORM.nnpickup_id"]},
			{type:"left", table: "ONSORDERFORMFULFILLMENT", condlist: ["ONSORDERFORMFULFILLMENT.onsorderformfulfillment_id=ONSORDERFORM.onsorderformfulfillment_id"]},
		],
		grouping: {},
		condlist: [
			"ONSORDERFORM.onscustomer_id="+customerid,
			"ONSORDERFORM.onsorderform_datetime>='"+defaultstartdate+"'"
		],
	}
	const orderInitstate = {
		"filter":[
			/*
			{
				"display": "Past 6 months",
				//"display":"Order date after '"+formatTools.datestr(defaultstartdate)+"'",
				"field":"onsorderform_datetime", "operation":" >= ","value":defaultstartdate},
			//{"display":"Order date before '7/1/2024'","field":"onsorderform_datetime", "operation":" < ","value":"2024-07-01"}
			*/
		],
		"orderbyfield": "onsorderform_datetime",
		"orderbymode": "desc"
	};

	return <div className={tablescroll.headerfooter +" "+styles.editscrollholder}>
		<table>
			<tbody>
				<tr>
					<td>
						<div className={styles.sectionpercent50}>
							<WebappWidgetCard
								title={"Information"}
								token={token}
								entity={"ONSPERSON"}
								fieldList={customerFields}
								mobilerowtitlefield={[]}
								filterfieldList={[]}
								userparam={customerQueryparams}
								showRefresh={false}
								customSetData={setCustomerInfo}
							/>
							<br/>
							<WebappWidgetTable
									title={"Contact"}
									token={token}
									entity={"ONSPERSONCONTACT"}
									fieldList={contactFields}
									mobilerowtitlefield={contactMobileTitle}
									filterfieldList={[]}
									userparam={contactQueryparams}
									initsearchstate={contactInitState}
									showControls={false}
									showRefresh={false}
								/>
						</div>
						<div className={styles.sectionpercent50}>
							<WebappWidgetTable
									title={"Organizations"}
									token={token}
									entity={"onspersonorganization"}
									fieldList={orgFields}
									mobilerowtitlefield={orgMobileTitle}
									filterfieldList={[]}
									userparam={orgQueryparams}
									initsearchstate={orgInitState}
									showControls={false}
									showRefresh={false}
								/>
							<br/>
							<WebappWidgetTable
									title={"Address"}
									token={token}
									entity={"ONSPERSONADDRESS"}
									fieldList={addressFields}
									mobilerowtitlefield={addressMobileTitle}
									filterfieldList={[]}
									userparam={addressQueryparams}
									initsearchstate={addressInitState}
									showControls={false}
									showRefresh={false}
								/>
						</div>
						<div>
							<WebappWidgetTable
									title={"Order History"}
									token={token}
									entity={"ONSORDERFORM"}
									fieldList={orderFields}
									mobilerowtitlefield={orderMobileTitle}
									filterfieldList={orderFilterFields}
									userparam={orderQueryparams}
									initsearchstate={orderInitstate}
									searchRowClick={orderformRowClick}

									showDownload={true}
									showControls={true}
									showRefresh={true}
								/>
						</div>
					</td>
				</tr>
			</tbody>
			<tfoot className="noprint">
				<tr>
					<td className={styles.editcontrolbox+" text-centered"}>
						<button className={"textbutton "+styles.editcontrolboxtextbutton} onClick={handleClose}>
							x&nbsp;&nbsp;{customername}
						</button>
					</td>
				</tr>
			</tfoot>
		</table>
		<PreviewpaneComponent
				title={panetitle}
				visible={panevisible}
				urlsrc={""}
				handleClose={()=>{ setPanevisible(false); }}
		>
			<OnsorderPage confirmonly={true} methodid={0} overridetoken={token} viewonly={true} location={customlocation} />
		</PreviewpaneComponent>
	</div>
}


export default CustomerProfileComponent;
