
import React, {useEffect, useState, useCallback} from "react"
import PropTypes from "prop-types"

import FormField from "../controls/formfield";

import * as styles from "../style.module.css"
import imgrefresh from "../../../images/buttons/refresh.png"

const webappsAPI = require("../../../../lib/requestWebapps");

const WebappWidgetCard = ({title, disabled, token, entity, fieldList, userparam, showRefresh, documentid, customFieldInfo, customFieldFragment, customSetData}) => {
	const [loading, setLoading] = useState(false)
	const [inputList, setInputList] = useState([]);

	const loadAPIParam = useCallback( () => {
		var apiparam = JSON.parse(JSON.stringify(userparam));
		if (apiparam.hasOwnProperty("sortlist")) {
			delete apiparam.sortlist;
		}
		if (apiparam.hasOwnProperty("getallrows")) {
			delete apiparam.getallrows;
		}
		return apiparam;
	}, [userparam]);

	const loadDBFieldlist = useCallback( () => {
		var dbfieldlist = [];
		var idx = 0;
		while (idx < fieldList.length) {
			if (fieldList[idx].hasOwnProperty("formula")) {
				dbfieldlist.push(fieldList[idx].formula+" as "+fieldList[idx].dbfield);

			} else {
				dbfieldlist.push(fieldList[idx].dbfield);
			}
			idx++;
		}
		return dbfieldlist;
	}, [fieldList]);

	const loadList = useCallback( (e) => {
		if (e) {
			e.preventDefault();
		}

		function updateInputList(record)
		{
			var tmpfield = {};
			var tmplist = [];

			var idx = 0;
			while (idx < fieldList.length) {
				tmpfield = {
					"label": fieldList[idx].label,
					"field": fieldList[idx].dbfield,
					"value": record.hasOwnProperty(fieldList[idx].dbfield)?record[fieldList[idx].dbfield]:"",
					"input": fieldList[idx].type,
					"mode": "readonly",
					"options": fieldList[idx].hasOwnProperty("options")?fieldList[idx].options:[],
					"modified": false,
					"loading": false
				};
				tmplist.push(tmpfield);
				idx++;
			}
			setInputList(tmplist);
			setLoading(false);
		}

		if (token !== "") {
			setLoading(true);
			var apiparam = loadAPIParam();

			apiparam.offset = 0;
			apiparam.count = 1;
			apiparam.fieldlist = loadDBFieldlist();

			webappsAPI.queryData(entity, apiparam, token).then(response => {
				if (response.status === "OK") {
					if (response.count > 0) {
						updateInputList(response.records[0]);
						if (typeof customSetData !== "undefined") {
							customSetData(response.records[0]);
						}
						return;
					}
				}
				setInputList([]);
				setLoading(false);
			});
		}
	}, [token, fieldList, entity, loadAPIParam, loadDBFieldlist, customSetData])

	useEffect(() => {
		if (token !== "") {
			loadList(null);
		}
	}, [token, loadList])

	function refreshData()
	{
		loadList(null);
	}


	return <>
		<div className={styles.searchheadercontrol+" text-centered"}>
			{(loading||title.length > 0) && <h2 className={styles.searchtabletitle}>
				{loading?
					"Please Wait..."
				:
				<>
					{title}
					{showRefresh && <button
						className={"iconbutton iconbutton-medium"}
						disabled={disabled || loading}
						onClick={(e)=>{refreshData()}}
						title={"Refresh"}
					>
						<img src={imgrefresh} alt="Refresh" />
					</button>}

				</>}
			</h2>}
		</div>
		{(loading === false) && <div className={styles.editfieldcontainer}>
			{ inputList.map((entry, idx) => {
				if (typeof customFieldFragment !== "undefined") {
					const tmpfragment = customFieldFragment(inputList, idx);
					if (tmpfragment !== null) {
						return tmpfragment
					}
				}
				var finalentrydata = entry;

				if (typeof customFieldInfo !== "undefined") {
					finalentrydata = customFieldInfo(inputList, idx);
				}

				if (finalentrydata.field === entity+"_id" || finalentrydata.input === "hidden") {
					return <input type={"hidden"} name={finalentrydata.field} value={finalentrydata.value} />
				}
				return <>
					<FormField
						fieldid={""+idx}
						fielddetails={finalentrydata}
						disabled={disabled || loading}
						viewmode={true}
						token={token}
						pkid={documentid}
					/>
				</>
			})}
		</div>}
	</>
}


WebappWidgetCard.propTypes = {
	showRefresh: PropTypes.bool,
	allowback: PropTypes.bool,
	allowdelete: PropTypes.bool,
	allowprint: PropTypes.bool,
	title: PropTypes.string,
	documentid: PropTypes.number
}


WebappWidgetCard.defaultProps = {
	showRefresh: false,
	allowback: true,
	allowdelete: false,
	allowprint: false,
	title: "",
	documentid: 0
}

export default WebappWidgetCard
